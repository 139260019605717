export default [
  {
    title: 'blog',
    url: '/blog',
  },
  {
    title: 'about',
    url: '/about',
  },
];
